<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.technicalnauticalservice.planservice')"
			:modelName="modelName"
			dialogName="planService"
			:onShow="onShowPlanService"
			:onOk="onOkPlanService"
			:showDialogProp="showPlanService"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`startdateplanned-pilotage`"
							v-model="model.startdateplanned"
							:label="$t('form.technicalnauticalservice.startdateplanned')"
							required
							toplabel
							time
						></pui-date-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`enddateplanned-pilotage`"
							v-model="model.enddateplanned"
							:label="$t('form.technicalnauticalservice.enddateplanned')"
							required
							toplabel
							time
						></pui-date-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.technicalnauticalservice.estimateservice')"
			:modelName="modelName"
			dialogName="estimateService"
			:onShow="onShowEstimateService"
			:onOk="onOkEstimateService"
			:showDialogProp="showEstimateService"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`startdateestimated-technicalnauticalservice`"
							v-model="model.startdateestimated"
							:label="$t('form.technicalnauticalservice.startdateestimated')"
							required
							toplabel
							time
						></pui-date-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`enddateestimated-technicalnauticalservice`"
							v-model="model.enddateestimated"
							:label="$t('form.technicalnauticalservice.enddateestimated')"
							required
							toplabel
							time
						></pui-date-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.technicalnauticalservice.initiateservice')"
			:modelName="modelName"
			dialogName="initiateService"
			:onShow="onShowInitiateService"
			:onOk="onOkInitiateService"
			:showDialogProp="showInitiateService"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`startdateactual-technicalnauticalservice`"
							v-model="model.startdateactual"
							:label="$t('form.technicalnauticalservice.startdateactual')"
							required
							toplabel
							time
							:max="model.enddateactual"
						></pui-date-field>
					</v-col>
					<v-col cols="6" v-if="modelName == 'pilotage' || modelName == 'towage'">
						<pui-select
							id="servicestarttypeid"
							attach="servicestarttypeid"
							:label="$t('form.technicalnauticalservice.servicestarttype')"
							v-model="model"
							modelName="servicestarttype"
							:itemsToSelect="[{ id: model.servicestarttype }]"
							:modelFormMapping="{ id: 'servicestarttype' }"
							itemValue="id"
							itemText="description"
							toplabel
							reactive
							:fixedFilter="filterServiceType"
						></pui-select>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.technicalnauticalservice.endservice')"
			:modelName="modelName"
			dialogName="endService"
			:onShow="onShowEndService"
			:onOk="onOkEndService"
			:showDialogProp="showEndService"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`enddateactual-technicalnauticalservice`"
							v-model="model.enddateactual"
							:label="$t('form.technicalnauticalservice.enddateactual')"
							required
							toplabel
							time
							:min="model.startdateactual"
						></pui-date-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.technicalnauticalservice.cancelservice')"
			:modelName="modelName"
			:dialogName="cancelService"
			:onShow="onShowCancelService"
			:onOk="onOkCancelService"
			:showDialogProp="showCancelService"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-select
							:id="`cancelationreason-${modelName}`"
							:attach="`cancelationreason-${modelName}`"
							v-model="model"
							:label="$t('modal.technicalnauticalservice.cancelationreason')"
							toplabel
							clearable
							modelName="cancelationreason"
							:itemsToSelect="[{ id: model.cancelationreasonid }]"
							:modelFormMapping="{ id: 'cancelationreasonid' }"
							itemValue="id"
							itemText="description"
							:order="{ description: 'asc' }"
							reactive
							required
						></pui-select>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import EventsMixin from '@/mixins/EventsMixin';

export default {
	name: 'stop-modals',
	mixins: [EventsMixin],
	components: {},
	data() {
		return {
			model: {
				startdateplanned: null,
				enddateplanned: null
			},
			showPlanService: true,
			showEstimateService: true,
			showInitiateService: true,
			showEndService: true,
			showCancelService: true,
			cancelService: 'cancelService'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	computed: {
		filterServiceType() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'technical_nautical_service_type', op: 'eq', data: this.model.servicetypeid }]
			};
		}
	},
	watch: {},
	methods: {
		onShowPlanService(modalData) {
			this.model = modalData;
			this.model.startdateplanned = this.model.startdatereq;
			this.model.enddateplanned = this.model.enddatereq;
		},
		onOkPlanService(modalData) {
			return new Promise(async (resolve) => {
				await this.doPlanService(modalData);
				resolve(true);
			});
		},
		async doPlanService(modalData) {
			this.$puiRequests.postRequest(
				'/technicalnauticalservice/plan',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showPlanService = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showPlanService = false;
				}
			);
		},
		onShowEstimateService(modalData) {
			this.model = modalData;
			this.model.startdateestimated = this.model.startdateplanned ? this.model.startdateplanned : this.model.startdatereq;
			this.model.enddateestimated = this.model.enddateplanned ? this.model.enddateplanned : this.model.enddatereq;
		},
		onOkEstimateService(modalData) {
			return new Promise(async (resolve) => {
				await this.doEstimateService(modalData);
				resolve(true);
			});
		},
		async doEstimateService(modalData) {
			this.$puiRequests.postRequest(
				'/technicalnauticalservice/estimate',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showEstimateService = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showEstimateService = false;
				}
			);
		},
		onShowInitiateService(modalData) {
			this.model = modalData;
			this.model.startdateactual = this.model.startdateestimated
				? this.model.startdateestimated
				: this.model.startdateplanned
				? this.model.startdateplanned
				: this.model.startdatereq;
		},
		onOkInitiateService(modalData) {
			return new Promise(async (resolve) => {
				await this.doInitiateService(modalData);
				resolve(true);
			});
		},
		async doInitiateService(modalData) {
			this.$puiRequests.postRequest(
				'/technicalnauticalservice/initiate',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showInitiateService = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showInitiateService = false;
				}
			);
		},
		onShowEndService(modalData) {
			this.model = modalData;
			this.model.enddateactual = this.model.enddateestimated
				? this.model.enddateestimated
				: this.model.enddateplanned
				? this.model.enddateplanned
				: this.model.enddatereq;
		},
		onOkEndService(modalData) {
			return new Promise(async (resolve) => {
				await this.doEndService(modalData);
				resolve(true);
			});
		},
		async doEndService(modalData) {
			this.$puiRequests.postRequest(
				'/technicalnauticalservice/end',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showEndService = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showEndService = false;
				}
			);
		},
		onShowCancelService(modalData) {
			this.model = modalData;
		},
		onOkCancelService(modalData) {
			return new Promise(async (resolve) => {
				await this.doCancelService(modalData);
				resolve(true);
			});
		},
		async doCancelService(modalData) {
			this.$puiRequests.postRequest(
				'/technicalnauticalservice/cancel',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showCancelService = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showCancelService = false;
				}
			);
		}
	}
};
</script>
